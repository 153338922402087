import React, { Fragment } from 'react';
import Fade from 'react-reveal/Fade';
import Container from 'common/components/UI/Container';
import { SectionHeader } from '../appModern.style';
import SectionWrapper, { TabArea } from './adminApp.style';
import content from '../../../content.json';
import Text from 'common/components/Text';

const AdminApp = () => {
  const { title } = content.webApp;

  return (
    <SectionWrapper>
      <Container>
        <SectionHeader>
          <Fade up>{/* <Text as="p" content={title} /> */}</Fade>
        </SectionHeader>
      </Container>
    </SectionWrapper>
  );
};

export default AdminApp;

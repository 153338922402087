import React from 'react'
import Box from 'common/components/Box';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import { TestRow, TestColRight, TestCol } from '../About/about.style';
import './styles.css'

function TestemonialCard({title, program, description, image, link}) {

    return (
        <div className='card-div'>

            <TestRow>
                
                    <TestCol>
                        <a target="_blank" href={link}>
                            <Box className="Left" style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '1rem'}}>
                                <Image src={image} alt="client-image"  className="testemonial-image" />
                                <div className="testemonial-header">
                                        <Heading as="h2" content={title} style={{marginBottom: '0.2rem', fontSize: '28px', color: '#fff'}}/>
                                        <Text as="p" content={program} style={{color: '#F2A143', fontWeight: 'normal'}}/>
                                </div>
                            </Box>
                        </a>
                    </TestCol>
                    <TestColRight>
                        <Box className="Right" style={{fontSize: '22px' }}>
                            <Text as="p" key={`track-text-key`}  className="testemonial-text"
                                style={{  }}
                                content={description} />
                        </Box>
                    </TestColRight>
            </TestRow>

        </div>
    )
}

export default TestemonialCard
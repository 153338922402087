import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import { SectionHeader } from '../appModern.style';
import SectionWrapper from './testimonial.style';
import './styles.css';
import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import { Arrow, Fade as FFade, Perspective } from '@egjs/flicking-plugins';
import '@egjs/react-flicking/dist/flicking-inline.css';
import '@egjs/flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/pagination.css';
import TestemonialCard from './TestemonialCard';
import content from '../../../../src/common/data/AppModern/index.json';
import { AutoPlay } from '@egjs/flicking-plugins';
import '@egjs/flicking-plugins/dist/arrow.css';
import { useTranslation } from 'react-i18next';

const Testimonial = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     appModernJson {
  //       testimonial {
  //         slogan
  //         title
  //         description
  //         reviews {
  //           id
  //           title
  //           description
  //           image
  //           program
  //         }
  //       }
  //     }
  //   }
  // `);
  const {t} = useTranslation();
  const reviewsTranslated = t('testimonial.reviews', { returnObjects: true });

  const _plugins = [
    new FFade(),
    new Perspective({ rotate: 0.5 }),
    new Arrow(),
    new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: false }),
  ];

  return (
    <SectionWrapper id="testimonial">
      {/* <Container> */}
      <SectionHeader className="test-header">
        <Fade up>
          <Heading as="h5" content={t('testimonial.slogan')} />
          <Heading content={t('testimonial.title')} style={{ color: '#fff' }} />
          <Text
            as="p"
            content={t('testimonial.description')}
            style={{ color: '#ababab', marginTop: '1rem' }}
          />
        </Fade>
      </SectionHeader>

      <div>
        <Flicking circular={true} plugins={_plugins} className="padding-bottom">
          {reviewsTranslated.map((review) => (
            <div className="flicking-panel card-panel" key={review.id}>
              <TestemonialCard
                title={review.title}
                description={review.description}
                image={review.image}
                program={review.program}
                link={review.link}
              />
            </div>
          ))}

          <ViewportSlot>
            <span className="flicking-arrow-prev"></span>
            <span className="flicking-arrow-next"></span>
          </ViewportSlot>
        </Flicking>
      </div>

      {/* <CarouselWrapper>
          <Fade up delay={100}>
            <GlideCarousel
              options={glideOptions}
              nextButton={
                <Button
                  icon={<i className="flaticon-next" />}
                  aria-label="Next"
                  variant="fab"
                />
              }
              prevButton={
                <Button
                  icon={<i className="flaticon-left-arrow" />}
                  aria-label="Prev"
                  variant="fab"
                />
              }
            >
              <Fragment>
                {reviews.map((item) => (
                  <GlideSlide key={`testimonial--key${item.id}`}>
                    <div className="review-card">
                      <Heading as="h3" content={item.title} />
                      <Text content={item.description} />
                      <Text content={item.name} />
                      <Image src={item.avatar} width="200px" alt="Avatar" />
                    </div>
                  </GlideSlide>
                ))}
              </Fragment>
            </GlideCarousel>
          </Fade>
        </CarouselWrapper> */}

      {/* </Container> */}
    </SectionWrapper>
  );
};

export default Testimonial;
